/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:a90b7ff2-9bfe-4e20-bc2c-7a3470df7c97",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_B8EDwGNq3",
    "aws_user_pools_web_client_id": "eiueb9dc0ba52srq8qjnrfnnj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://pxu5q7jczfavbnlhtqubrl7rdq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uez3rhwvbvdknivdcrydqyvw3y",
    "aws_user_files_s3_bucket": "todoclubc8d9ea3a872d4f9482d07a5269bbc147222748-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
